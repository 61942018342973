<template>
    <painel titulo="Projetos e Ações" icone="pi pi-sliders-h" :refreshFunction="obterProjetosAcoes">
        <tabela :data="projetosAcoes" :globalFilterFields="['codProjeto', 'projeto', 'codAcao', 'acao']" stateKey="dt-senac-usuario-projetosacoes">
            <template #botoes>
                <btn-inserir-projeto-acao
                    :usuario="usuario"
                    @obter-usuario-atualizado="$emit('obter-usuario-atualizado')"
                    v-if="$temAcessoView('SENAC-USUARIOS-04')"
                ></btn-inserir-projeto-acao>
            </template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-excluir-projeto-acao
                            :usuario="usuario"
                            :projetoAcao="slotProps.data"
                            @obter-usuario-atualizado="$emit('obter-usuario-atualizado')"
                            v-if="$temAcessoView('SENAC-USUARIOS-04')"
                        ></btn-excluir-projeto-acao>
                    </template>
                </Column>
                <Column field="projeto" header="Projeto" :sortable="true">
                    <template #body="slotProps">
                        <span>{{ slotProps.data.projeto }} ({{ slotProps.data.codProjeto }})</span>
                    </template>
                </Column>
                <Column field="acao" header="Ação" :sortable="true">
                    <template #body="slotProps"> {{ slotProps.data.acao }} ({{ slotProps.data.codAcaoSeq }}) </template>
                </Column>
            </template>
        </tabela>
    </painel>
</template>

<script>
import BtnInserirProjetoAcao from './BtnInserirProjetoAcao';
import BtnExcluirProjetoAcao from './BtnExcluirProjetoAcao';

export default {
    props: {
        usuario: {
            type: Object,
            default: () => ({}),
        },
    },

    components: {
        BtnInserirProjetoAcao,
        BtnExcluirProjetoAcao,
    },

    emits: ['obter-usuario-atualizado'],

    data() {
        return {
            projetosAcoes: [],
        };
    },

    methods: {
        obterProjetosAcoes() {
            this.$emit('obter-usuario-atualizado');
        },
    },

    watch: {
        usuario() {
            this.projetosAcoes = this.usuario.projetosAcoes;
        },
    },
};
</script>
