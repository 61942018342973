<template>
    <font-awesome-icon icon="trash" @click="confirmar()" size="lg" class="icon-button p-text-danger" title="Excluir" />
</template>

<script>
import UsuariosServices from './services';

export default {
    props: {
        usuario: {
            type: Object,
        },

        projetoAcao: {
            type: Object,
        },
    },

    emits: ['obter-usuario-atualizado'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir o projeto ${this.projetoAcao.projeto} e a ação ${this.projetoAcao.acao}?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            let usuarioProjetoAcaoDto = {
                usuarioId: this.usuario.usuarioId,
                codProjeto: this.projetoAcao.codProjeto,
                codAcaoSeq: this.projetoAcao.codAcaoSeq,
            };
            UsuariosServices.excluirProjetoAcao(usuarioProjetoAcaoDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Usuário',
                        detail: 'Projeto/Ação excluído com sucesso',
                        life: 3000,
                    });
                    this.$emit('obter-usuario-atualizado');
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Usuário',
                        detail: response.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
