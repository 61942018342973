<template>
    <Button label="Ativar" icon="pi pi-check" class="ml-2" @click="ativarDesativar('A')" v-if="$temAcessoView('SENAC-USUARIOS-03') && usuario?.status == 0" />
    <Button
        label="Desativar"
        icon="pi pi-times"
        class="ml-2 p-button-danger"
        @click="ativarDesativar('D')"
        v-if="$temAcessoView('SENAC-USUARIOS-03') && usuario?.status == 1"
    />
</template>

<script>
import UsuariosServices from './services';

export default {
    props: {
        usuario: {
            type: Object,
        },
    },

    emits: ['obter-usuario-atualizado'],

    methods: {
        ativarDesativar(acao) {
            this.$store.dispatch('addRequest');
            let promisse;
            if (acao == 'A') {
                promisse = UsuariosServices.ativar(this.$route.params.id);
            } else {
                promisse = UsuariosServices.desativar(this.$route.params.id);
            }
            promisse.then((response) => {
                if (response?.success) {
                    this.$store.dispatch('setAtualizar', true);
                    this.$emit('obter-usuario-atualizado');
                    this.$toast.add({ severity: 'success', summary: 'Usuário', detail: 'Usuário atualizado com sucesso', life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Usuário', detail: 'Erro ao atualizar usuário', life: 3000 });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
