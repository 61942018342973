<template>
    <painel titulo="SENAC - Usuário" icone="pi pi-user" :refreshFunction="obterUsuario">
        <div class="mb-4">
            <detalhe titulo="Login">{{ usuario?.login }}</detalhe>
            <detalhe titulo="Nome">{{ usuario?.nome }}</detalhe>
            <detalhe titulo="Status">
                <status :status="usuario?.statusDescr"></status>
            </detalhe>
        </div>
        <btn-voltar :route="{ name: 'Senac_Usuarios' }"></btn-voltar>
        <btn-ativar-desativar :usuario="usuario" @obter-usuario-atualizado="obterUsuario()"></btn-ativar-desativar>
    </painel>
    <projetos-acoes :usuario="usuario" @obter-usuario-atualizado="obterUsuario()"></projetos-acoes>
</template>

<script>
import UsuariosServices from './services';
import ProjetosAcoes from './ProjetosAcoes';
import BtnAtivarDesativar from './BtnAtivarDesativar';

export default {
    components: {
        ProjetosAcoes,
        BtnAtivarDesativar,
    },

    data() {
        return {
            usuario: null,
        };
    },

    methods: {
        obterUsuario() {
            this.$store.dispatch('addRequest');
            UsuariosServices.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.usuario = response.data;
                } else {
                    this.usuario = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        voltar() {
            this.$router.push({ name: 'Senac_Usuarios' });
        },
    },

    mounted() {
        this.obterUsuario();
    },
};
</script>
