<template>
    <Button label="Inserir Projeto/Ação" icon="pi pi-plus" iconPos="left" @click="abrir()" class="p-button" />
    <Dialog
        v-model:visible="mostrar"
        :modal="true"
        :style="{ width: '50vw' }"
        :contentStyle="{ overflow: 'visible' }"
        @show="obterProjetosAcoes()"
        @hide="resetar()"
    >
        <template #header>
            <h5>Inserir Projeto/Ação</h5>
        </template>
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12">
                <label class="required">Projeto</label>
                <Dropdown
                    v-model="codProjeto"
                    :options="projetos"
                    optionLabel="nomePratif"
                    optionValue="codPratif"
                    :filter="true"
                    placeholder="Selecione o projeto"
                    :filterFields="['nomePratif']"
                    v-if="projetos"
                ></Dropdown>
            </div>
            <div class="field col-12">
                <label class="required">Ação</label>
                <Dropdown
                    v-model="codAcao"
                    :options="acoesProjeto"
                    optionLabel="nomeAcao"
                    optionValue="codAcaoSeq"
                    :filter="true"
                    placeholder="Selecione a ação"
                    :filterFields="['nomeAcao']"
                    v-if="acoesProjeto"
                ></Dropdown>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="fechar()" autofocus />
            <Button label="Salvar" icon="pi pi-check" @click="confirmarSalvar()" />
        </template>
    </Dialog>
</template>

<script>
import UsuariosServices from './services';

export default {
    props: {
        usuario: {
            type: Object,
            default: () => ({}),
        },
    },

    emits: ['obter-usuario-atualizado'],

    data() {
        return {
            mostrar: false,
            erros: [],
            projetos: [],
            codProjeto: null,
            acoes: [],
            codAcao: null,
            acaoInicial: true,
        };
    },

    methods: {
        abrir() {
            this.mostrar = true;
        },

        fechar() {
            this.mostrar = false;
            this.resetar();
        },

        resetar() {
            this.erros = [];
            this.codProjeto = null;
            this.codAcao = null;
        },

        obterProjetosAcoes() {
            this.$store.dispatch('addRequest');
            UsuariosServices.obterProjetosAcoes().then((response) => {
                if (response?.success) {
                    this.projetos = response.data.projetos;
                    this.codProjeto = this.projetos ? this.projetos[0]?.codPratif : null;
                    this.acoes = response.data.acoes;
                } else {
                    this.erros = response.errors[0];
                }
                this.$store.dispatch('removeRequest');
            });
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja inserir o projeto ${this.projetoDescricao} e a ação ${this.acaoDescricao}?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            this.$store.dispatch('addRequest');
            let projetoAcaoDto = {
                usuarioId: this.usuario.usuarioId,
                codProjeto: this.codProjeto,
                projeto: this.projetoDescricao,
                codAcaoSeq: this.codAcao,
                acao: this.acaoDescricao,
            };
            UsuariosServices.inserirProjetoAcao(projetoAcaoDto).then((r) => {
                if (r) {
                    if (r.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Usuário',
                            detail: 'Projeto/Ação inserido com sucesso',
                            life: 3000,
                        });
                        this.$emit('obter-usuario-atualizado');
                        this.fechar();
                    } else {
                        this.erros = r.errors;
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    computed: {
        acoesProjeto() {
            return this.acoes.filter((item) => {
                return item.codPratif === this.codProjeto;
            });
        },

        projetoDescricao() {
            return this.projetos.find((p) => p.codPratif === this.codProjeto)?.nomePratif;
        },

        acaoDescricao() {
            return this.acoes.find((a) => a.codAcaoSeq === this.codAcao)?.nomeAcao;
        },
    },

    watch: {
        acoesProjeto() {
            if (this.acoesProjeto) {
                this.codAcao = this.acoesProjeto[0]?.codAcaoSeq;
            }
        },
    },
};
</script>
